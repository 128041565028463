import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="bulk-referral-updates"
export default class extends Controller {
  static targets = [
    'selectedCount',
    'selectedReferralIds',
    'bulkUpdateFields',
    'fieldTemplate',
    'fields',
    'fieldNames',
    'availableFieldsSelect',
  ]

  connect() {
    this.initializeSelectedTargets()
    this.availableFields = JSON.parse(this.fieldsTarget.innerHTML)
    this.fieldNames = JSON.parse(this.fieldNamesTarget.innerHTML)
    this.updateAvailableFieldsSelect()
  }

  updateAvailableFieldsSelect() {
    const emptyOption = '<option value=""> - Select a field -</option>'
    const availableOptions = Object.keys(this.availableFields).map(
      (fieldName) =>
        `<option data-type="${this.availableFields[fieldName]}" value="${fieldName}">${this.fieldNames[fieldName]}</option>`,
    )
    this.availableFieldsSelectTarget.innerHTML =
      emptyOption + availableOptions.join('')
  }

  initializeSelectedTargets() {
    if (this.initialized) {
      return
    }
    this.initialized = true
    this.localStorageSelectedTargets =
      JSON.parse(localStorage.getItem('selectedTargets')) || []
  }

  addField(event) {
    event.preventDefault()
    const selectedField = this.availableFieldsSelectTarget.value
    const enumFields = [
      'client_status',
      'engagement_method',
      'transfer_type',
      'contact_instructions',
    ]
    const standardTypes = ['string', 'date', 'decimal']
    const fieldType = this.availableFields[selectedField]
    if (enumFields.includes(selectedField)) {
      this.copyEnumField(selectedField)
    } else if (fieldType === 'boolean') {
      this.addYesNoField(selectedField)
    } else if (standardTypes.includes(fieldType)) {
      this.addStandardField(selectedField, fieldType)
    } else {
      console.log('field type not handled', selectedField, fieldType)
      return
    }
    const newAvailableFields = { ...this.availableFields }
    delete newAvailableFields[selectedField]

    this.availableFields = newAvailableFields
    this.updateAvailableFieldsSelect()
  }

  addStandardField(fieldName, type) {
    const fieldLabel = this.fieldNames[fieldName]
    var newContainer = document.createElement('div')
    var newInput = document.createElement('input')
    newInput.setAttribute('type', type)
    newInput.setAttribute('class', 'text-field border-iron-200')
    newInput.setAttribute('id', fieldName)
    newInput.setAttribute('name', `bulk_referral_update[${fieldName}]`)
    var newLabel = document.createElement('label')
    newLabel.setAttribute('for', `bulk_referral_update[${fieldName}]`)
    newLabel.textContent = fieldLabel
    newLabel.setAttribute('class', 'block')
    newContainer.appendChild(newLabel)
    newContainer.appendChild(newInput)
    this.bulkUpdateFieldsTarget.appendChild(newContainer)
  }

  addYesNoField(fieldName) {
    const fieldLabel = this.fieldNames[fieldName]
    var newContainer = document.createElement('div')
    var newInput = document.createElement('select')
    newInput.setAttribute('class', 'text-field border-iron-200')
    newInput.setAttribute('id', fieldName)
    newInput.setAttribute('name', `bulk_referral_update[${fieldName}]`)
    var newOption = document.createElement('option')
    newOption.setAttribute('value', 'true')
    newOption.textContent = 'Yes'
    newInput.appendChild(newOption)
    newOption = document.createElement('option')
    newOption.setAttribute('value', 'false')
    newOption.textContent = 'No'
    newInput.appendChild(newOption)
    var newLabel = document.createElement('label')
    newLabel.setAttribute('for', `bulk_referral_update[${fieldName}]`)
    newLabel.textContent = fieldLabel
    newLabel.setAttribute('class', 'block')
    newContainer.appendChild(newLabel)
    newContainer.appendChild(newInput)
    this.bulkUpdateFieldsTarget.appendChild(newContainer)
  }

  copyEnumField(copyField) {
    const newSelect = this.fieldTemplateTargets
      .find((el) => el.dataset.field === copyField)
      .cloneNode(true)
    this.bulkUpdateFieldsTarget.appendChild(newSelect)
  }

  refreshSelectedDisplay() {
    const selectedKeys = Object.keys(this.localStorageSelectedTargets).filter(
      (k) => this.localStorageSelectedTargets[k],
    )
    this.selectedCountTarget.textContent = selectedKeys.length
  }

  removeReferral(event) {
    const eventTarget = event.currentTarget

    const referralId = eventTarget.getAttribute('data-referral-id')
    const removedTarget = { [referralId]: false }
    const updatedTargets = {
      ...this.localStorageSelectedTargets,
      ...removedTarget,
    }
    this.localStorageSelectedTargets = updatedTargets
    localStorage.setItem('selectedTargets', JSON.stringify(updatedTargets))

    this.selectedReferralIdsTargets.forEach((target) => {
      if (target.value === referralId) {
        target.remove()
      }
    })

    eventTarget.closest('tr').remove()

    this.refreshSelectedDisplay()
  }
}
