import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="referral-list"
export default class extends Controller {
  static targets = [
    'referralSelection',
    'bulkEditLink',
    'clearLink',
    'selectAllNone',
  ]

  connect() {
    this.initializeSelectedTargets()
    this.refreshSelectedDisplay()
  }

  initializeSelectedTargets() {
    if (this.initialized) {
      return
    }
    this.initialized = true
    this.localStorageSelectedTargets =
      JSON.parse(localStorage.getItem('selectedTargets')) || []
  }

  referralSelectionTargetConnected(target) {
    this.initializeSelectedTargets()
    if (this.localStorageSelectedTargets[target.value]) {
      target.checked = true
    }
  }

  updateSelectedIds() {
    const checkedTargets = this.referralSelectionTargets
      .map((k, v) => {
        return { id: k.value, checked: k.checked }
      })
      .reduce((acc, { id, checked }) => {
        acc[id] = checked
        return acc
      }, {})

    const updatedTargets = {
      ...this.localStorageSelectedTargets,
      ...checkedTargets,
    }
    this.localStorageSelectedTargets = updatedTargets
    localStorage.setItem('selectedTargets', JSON.stringify(updatedTargets))

    this.refreshSelectedDisplay()
  }

  bulkEdit(event) {
    event.preventDefault()
    const selectedKeys = Object.keys(this.localStorageSelectedTargets).filter(
      (k) => this.localStorageSelectedTargets[k],
    )
    if (selectedKeys.length > 0) {
      const url =
        '/bulk_referral_updates/new?selected_ids=' + selectedKeys.join(',')
      window.location.href = url
    }
  }

  refreshSelectedDisplay() {
    const selectedKeys = Object.keys(this.localStorageSelectedTargets).filter(
      (k) => this.localStorageSelectedTargets[k],
    )
    const length = selectedKeys.length
    if (length > 0) {
      this.clearLinkTarget.classList.remove('hidden')
      this.bulkEditLinkTarget.classList.remove('hidden')
      this.bulkEditLinkTarget.innerHTML = `Edit ${length} referrals`
    } else {
      this.clearLinkTarget.classList.add('hidden')
      this.bulkEditLinkTarget.classList.add('hidden')
    }
  }

  clearSelectedIds() {
    this.localStorageSelectedTargets = {}
    localStorage.removeItem('selectedTargets')
    this.referralSelectionTargets.forEach((checkbox) => {
      checkbox.checked = false
    })
    this.selectAllNoneTarget.checked = false
    this.refreshSelectedDisplay()
  }

  selectAllNone(event) {
    this.referralSelectionTargets.forEach((checkbox) => {
      checkbox.checked = event.target.checked
    })
    this.updateSelectedIds()
  }
}
